import React from 'react';
import '@tencent/coral-style';
import { StickyTool } from '@tencent/coral-design';
import { FeedbackLine, PhoneLine, OfficialAccountsLine, EnterpriseWechatLine } from '@tencent/coral-icon';
import groupImg from './img/group.png';
import publicImg from './img/public.jpeg';
import mobileImg from './img/mobile.png';
import PropTypes from 'prop-types';
import './index.less';

export const PAGE_TYPE = {
  /** 管理后台端 */
  DASHBOARD: 'DASHBOARD',
  /** ce惜议端 */
  ENTERPRISE: 'ENTERPRISE',
  /** pc客户端 */
  PRODUCTS: 'PRODUCTS',
  /** pc客户端首页 */
  HOME: 'HOME',
};

export class LayoutStickyTool extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      scroller: null,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    const { props } = this;
    let data = [];
    let scroller = null;
    const mobileContent = {
      icon: <PhoneLine />,
      text: '移动端',
      hoverContent: (
        <div className="layout-sticky-tool-hover-content">
          <img src={mobileImg} alt="移动端二维码" />
          <span className="layout-sticky-tool-hover-content-text">手机微信扫码</span>
        </div>
      ),
    };
    const publicContent = {
      icon: <OfficialAccountsLine />,
      text: '公众号',
      hoverContent: (
        <div className="layout-sticky-tool-hover-content">
          <img src={publicImg} alt="公众号二维码" />
          <span className="layout-sticky-tool-hover-content-text">扫码关注</span>
        </div>
      ),
    };
    const groupContent = {
      icon: <EnterpriseWechatLine />,
      text: '用户群',
      hoverContent: (
        <div className="layout-sticky-tool-hover-content">
          <img src={groupImg} alt="用户群二维码" />
          <span className="layout-sticky-tool-hover-content-text">扫码加入</span>
        </div>
      ),
    };
    switch (props.type) {
    case PAGE_TYPE.DASHBOARD:
      data = [
        groupContent,
        {
          icon: <FeedbackLine />,
          text: '产品社区',
          target: '_blank',
          href: 'https://support.qq.com/products/1368',
        },
      ];
      break;
    case PAGE_TYPE.PRODUCTS:
      data = [
        mobileContent,
        publicContent,
        groupContent,
        {
          icon: <FeedbackLine />,
          text: '产品社区',
          target: '_blank',
          href: 'https://support.qq.com/products/1368',
        },
      ];
      break;
    case PAGE_TYPE.HOME:
      data = [
        mobileContent,
        publicContent,
        {
          icon: <FeedbackLine />,
          text: '产品社区',
          target: '_blank',
          href: 'https://support.qq.com/products/1368',
        },
      ];
      break;
    case PAGE_TYPE.ENTERPRISE:
      data = [
        {
          icon: <FeedbackLine />,
          text: '产品社区',
          target: '_blank',
          href: 'https://txc.qq.com/enterprise/1/all-products',
        },
      ];
      scroller = document.querySelector('.enterprise-layout');
      break;
    default:
      data = [
        {
          icon: <FeedbackLine />,
          text: '产品社区',
          target: '_blank',
          href: 'https://support.qq.com/products/1368',
        },
      ];
      break;
    }
    this.setState({
      data,
      scroller,
    });
  }

  render() {
    const { state, props } = this;
    if (props.type === PAGE_TYPE.ENTERPRISE && !state.scroller) return null;
    return <StickyTool
      className="layout-sticky-tool"
      scroller={state.scroller}
      data={state.data}
    />;
  }
}

LayoutStickyTool.propTypes = {
  type: PropTypes.string,
};
